import React from "react"
import Layout from "../components/layout"
import StyledFullBackground from "../components/background"

export default function Page404() {
  return (
    <Layout>
      <StyledFullBackground></StyledFullBackground>

      <section className="content">
        <h1 className="heading">Page Not Found</h1>
        <p>
          Head back to the <a href="/index.html">home page</a> and we'll get you
          back on the road.
        </p>
      </section>
    </Layout>
  )
}
