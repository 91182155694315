import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { StyledFullScreenWrapper } from "./SharedStyledComponents"

const FullBackground = ({
  sources,
  className,
  alignItems,
  fullscreen,
  children,
}) => {
  return (
    <StyledFullScreenWrapper id="fullscreenwrapper" fullscreen={fullscreen}>
      <BackgroundImage
        Tag="section"
        className={className}
        fluid={sources}
        backgroundColor={`#000000`}
        id="fullscreenbg"
        role="img"
        alignItems={alignItems}
        fullscreen={fullscreen}
        preserveStackingContext={true}
      >
        {children}
      </BackgroundImage>
    </StyledFullScreenWrapper>
  )
}

const StyledFullBackground = styled(FullBackground)`
  width: 100%;
  ${props => props.fullscreen && "height: 100vh;"};
  display: flex;
  align-items: ${props => props.alignItems || "center"};
  justify-content: center;
  position: relative;
  &::before,
  &::after {
    background-position: center center;
    @media (min-width: 600px) {
      background-position: right center !important;
    }
  }
  background-attachment: relative;
`

export default StyledFullBackground
